import { getCommonParamsOptions } from '@/utils/common'

export const baseFormConfig = {
  // colLayout: {
  //   span: 8
  // },
  formItems: [
    {
      field: 'cardBin',
      type: 'input',
      label: 'card.card-bin',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'bankName',
      type: 'input',
      label: 'card.bank-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'user.tenant',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'status',
      type: 'select',
      label: 'common.app-status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('status'),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        disabled: true
      },
      colLayout: {
        xl: 16,
        lg: 16,
        md: 16,
        sm: 16,
        xs: 16
      }
    }
  ]
}
